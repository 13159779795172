import classes from './Project.module.css';
import { useState } from 'react';

export default function Project(props) {
  return (
    <div className={classes.projectContent}>
      <section className={classes.projectTitleSection}>
        <div className={classes.projectTitleBox}>
          <h3 className={classes.projectTitle}>{props.title}</h3>
        </div>
      </section>
      <section className={classes.projectDescriptonSection}>
        <div className={classes.projectDescriptionBox}>
          <p className={classes.projectDescription}>{props.description}</p>
        </div>
      </section>
      <section className={classes.projectSkillsSection}>
        <div className={classes.projectSkillsBox}>
          <ul>
            {props.skills.map((skill, index) => (
              <li key={index}>{skill}</li>
            ))}
          </ul>
        </div>
      </section>
      <section className={classes.projectDetailLinks}>
        <div className={classes.projectDetailLinksBox}>
          <a href={props.link}>Github</a>

          {/* {props.isDownload && (
            <a href={props.pdfFile} download="KOKKALIS KONSTANTINOS CV.pdf">
              {props.pdfName}
            </a>
          )} */}
          {!props.isDownload && props.pdfFile != undefined && (
            <a href={props.pdfFile}>{props.pdfName}</a>
          )}
        </div>
      </section>
    </div>
  );
}
