import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  root
);

// let scrollDirection = 0;
// let scrollAmount = 0;
// const scrollThreshold = 50; // Adjust this value for sensitivity

// function handleAutoScroll(event) {
//   const windowHeight = window.innerHeight;
//   const mouseY = event.clientY;

//   if (mouseY > windowHeight - scrollThreshold) {
//     scrollDirection = 1; // Scroll down
//     scrollAmount = 8; // Adjust the scroll speed
//   } else if (mouseY < scrollThreshold) {
//     scrollDirection = -1; // Scroll up
//     scrollAmount = 8; // Adjust the scroll speed
//   } else {
//     scrollDirection = 0; // Stop scrolling
//   }
// }

// function autoScroll() {
//   if (scrollDirection === 1) {
//     window.scrollBy(0, scrollAmount);
//   } else if (scrollDirection === -1) {
//     window.scrollBy(0, -scrollAmount);
//   }
//   requestAnimationFrame(autoScroll);
// }

// document.addEventListener('mousemove', handleAutoScroll);
// autoScroll();
