import classes from './ContactPage.module.css';
import MainLayout from '../layouts/MainLayout';
import ContactForm from '../components/forms/ContactForm';
import linkedin from '../images/linkedin.png';
import instagram from '../images/instagram.png';
import github from '../images/github-sign.png';
function ContactPage(props) {
  return (
    <section id="contact">
      <div className={classes.contactPageContent}>
        {/* <section className={classes.contactFormSection}>
          <ContactForm />
        </section> */}
        <section className={classes.contactLinksSection}>
          <a href="https://www.linkedin.com/in/konstantinos-kokkalis-806821221/">
            <img src={linkedin} alt="Linkedin" />
          </a>
          <a href="https://github.com/Kokkales">
            <img src={github} alt="Github" />
          </a>
          <a href="https://www.instagram.com/k.kokkalis/">
            <img src={instagram} alt="Instagram" />
          </a>
          <a href="https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=DmwnWrRnZnQbxLzwZZZJkrNfLDKBtXzPhNnMkwcqNNJBxnHSrBhwmwnKzgvtvctbdgbGBGSXJpZL">
            kokkaleskonstantinos@gmail.com
          </a>
        </section>
      </div>
    </section>
  );
}

export default ContactPage;
