import MainNavigationBar from '../components/MainNavigationBar';
import classes from './MainLayout.module.css';

export default function MainLayout(props) {
  return (
    <div className={classes.mainLayoutContent}>
      <section className={classes.mainNavigationSection}>
        <MainNavigationBar />
      </section>
      <section className={classes.contentSection}>{props.children}</section>
    </div>
  );
}
