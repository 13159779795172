import React, { useEffect, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import classes from './MainNavigationBar.module.css';

function MainNavigation(props) {
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    function handleScroll() {
      const sections = ['home', 'about', 'projects', 'contact'];

      for (const section of sections) {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= 150 && rect.bottom >= 150) {
            setActiveSection(section);
            // console.log(activeSection);
          }
        }
      }
    }
    // console.log(activeSection);

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activeSection]);

  return (
    <nav className={classes.mainNavigationBar}>
      <ul className={classes.mainUl}>
        {activeSection != 'home' && (
          <li className={classes.notActive}>
            <Link className={classes.link} smooth to="/#home">
              Home
            </Link>
          </li>
        )}
        {activeSection == 'home' && (
          <li className={classes.active}>
            <Link className={classes.link} smooth to="/#home">
              Home
            </Link>
          </li>
        )}
        {activeSection != 'about' && (
          <li className={classes.notActive}>
            <Link className={classes.link} smooth to="/#about">
              About
            </Link>
          </li>
        )}
        {activeSection == 'about' && (
          <li className={classes.active}>
            <Link className={classes.link} smooth to="/#about">
              About
            </Link>
          </li>
        )}
        {activeSection != 'projects' && (
          <li className={classes.notActive}>
            <Link className={classes.link} smooth to="/#projects">
              Projects
            </Link>
          </li>
        )}
        {activeSection == 'projects' && (
          <li className={classes.active}>
            <Link className={classes.link} smooth to="/#projects">
              Projects
            </Link>
          </li>
        )}
        {activeSection != 'contact' && (
          <li className={classes.notActive}>
            <Link className={classes.link} smooth to="/#contact">
              Contact
            </Link>
          </li>
        )}
        {activeSection == 'contact' && (
          <li className={classes.active}>
            <Link className={classes.link} smooth to="/#contact">
              Contact
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
}

export default MainNavigation;
