import MainNavigationBar from '../components/MainNavigationBar';
import MainLayout from '../layouts/MainLayout';
import classes from './HomePage.module.css';
import backgoundImg from '../images/background.png';

function HomePage(props) {
  return (
    <section id="home">
      <div className={classes.homePageContent}>
        <div className={classes.introBox}>
          <h1 className={classes.intro}>Konstantinos Kokkalis</h1>
        </div>
        <div className={classes.quoteBox}>
          <blockquote>
            <p>
              &ldquo;The only way to do great work
              <br /> is to love what you do.&ldquo;
            </p>
            <cite>Steve Jobs</cite>
          </blockquote>
        </div>
      </div>
    </section>
  );
}

export default HomePage;
