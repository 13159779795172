import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import ProjectPage from './pages/ProjectPage';
import MainLayout from './layouts/MainLayout';

function App() {
  return (
    <MainLayout>
      <div>
        {' '}
        {/* <MainNavigation /> */} <HomePage />
        <AboutPage />
        <ProjectPage />
        <ContactPage />
      </div>{' '}
    </MainLayout>
    // <Routes>
    //   <Route path="/" element={<HomePage />} />{' '}
    //   <Route path="/about" element={<AboutPage />} />{' '}
    //   <Route path="/contact" element={<ContactPage />} />{' '}
    //   <Route path="/project" element={<ProjectPage />} />{' '}
    // </Routes>
  );
}

export default App;
