import classes from './ProjectPage.module.css';
import MainLayout from '../layouts/MainLayout';
import Project from '../components/projects/Project';
import divorcePdf from '../otherFiles/divorceService.pdf';
import cq from '../otherFiles/circularQueue.pdf';
import dataMiningPdf from '../otherFiles/dataMiningDocumentation.pdf';
function ProjectPage(props) {
  return (
    <section className={classes.projectPage} id="projects">
      <div className={classes.projectPageContent}>
        <div className={classes.test}>
          <Project
            title="Oscar predictions - Data Mining"
            description="Predicting Oscar winners by training various models Like KNN, Gradient Booster, Tree classifier, Logistic regression"
            skills={['Python', 'Scikit-learn', 'pandas', 'numpy']}
            link="https://github.com/Kokkales/Oscars---Data-Mining"
            pdfFile={dataMiningPdf}
            pdfName="Documentation"
            isDownload={false}
          />
          <Project
            title="Resource Utilization Calculator"
            description="Calculating the resources utilization in my system while sending different requests, in different size batches and with different frequencies to a restAPI."
            skills={['Python']}
            link="https://github.com/Kokkales/Cloud-Management"
            // pdfFile={dataMiningPdf}
            // pdfName="Documentation"
            isDownload={false}
          />
          <Project
            title="Divorce Service"
            description="University Assgnment. A service for Lawyers, Spouses and Notaries to the divorce proccess easier."
            skills={[
              'React Js',
              'Javascript',
              'Html',
              'Css',
              'Java',
              'SpringBoot',
            ]}
            link="https://github.com/Kokkales/HUA.DistributedSystems.22055-22047-22113-Frontend"
            pdfFile={divorcePdf}
            pdfName="Documentation"
            isDownload={false}
          />
          <Project
            title="Travel Agency"
            description="University Assignment. Is a platform that recommends user travel destinations according to their preferences and Covid-19 restrictions."
            skills={['Java', 'JavaFX']}
            link="https://github.com/jtsoukalas/OOPII_22055_22047_22113"
            pdfFile="https://github.com/jtsoukalas/OOPII_22055_22047_22113/tree/newMaster/.readmeFiles"
            pdfName="Travel Agency Docs"
            isDownload={false}
          />

          <Project
            title="OS-Worker Pool"
            description="University Assignment. Parent process creates child proccesses to do different job each and when they finish they have a Gracefull Shutdown."
            skills={['C', 'Unix', 'Docker', 'Python']}
            link="https://github.com/Kokkales/OS-Worker_Pool"
            // pdfFile={divorcePdf}
            // pdfName="Documentation"
            isDownload={false}
          />
          <Project
            title="Data Visualization"
            description="University Assignment. Visualizing Data after proccessing an Excel Dataset (data cleaning)."
            skills={['React JS', 'Node.js', 'Python', 'SQL']}
            link="https://github.com/oduwancheekee/DBS_project"
            // pdfFile="pdfFile.pdf"// pdfName="Documentation"
            isDownload={false}
          />
          <Project
            title="Circular Queue"
            description="University Assignment. A project in the course of data structures."
            skills={['Java', 'Data Structures']}
            link="https://github.com/jtsoukalas/DS_22055_22047_22113"
            pdfFile={cq}
            pdfName="Documentation"
            isDownload={false}
          />
          <Project
            title="Chat App"
            description="Coming Soon..."
            skills={['Node.js', 'React.js', 'Javascript', 'Web Sockets']}
            link="https://github.com/Kokkales/Chat-WebApp"
            // pdfFile={cq}
            // pdfName="Documentation"
            isDownload={false}
            ready={false}
          />
          {/* <Project
            title=""
            description=""
            skills={['skill 1 ', 'skill 2', 'skill 3']}
            link="https://githubLink"
            pdfFile="pdfFile.pdf"
          /> */}
          <div className={classes.seeMoreLink}>
            {/* <button> */}
            <a href="https://github.com/Kokkales">See more..</a>
            {/* </button> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProjectPage;
